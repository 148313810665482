.using-platform-section{
    img{
        margin-bottom: 25px;
    }
    .small-image{
        max-width: 300px !important;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    a{
        color: #52B788;
        font-weight: 600;
    }
}