@import '../../assets/less/_variables.less';

.home-list{
    .ant-col{
        height: 100%;
    }
    .ant-list-item{
        margin-top: 15px !important;   
        background: @white;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
        border-radius: 8px;
        padding: 20px !important;
        cursor: pointer;
        transition: all ease-in 0.1s;
        @media screen and (max-width:1400px) {
            padding: 15px !important;
        }
        &:hover{
            transform: scale(1.05);
        }
        .ant-list-item-meta-title{
            font-size: 18px;
            font-weight: 500;
            @media screen and (max-width:1400px) {
                font-size: 16px;
            }
        }
        .ant-list-item-meta-avatar{
            .icon{
                fill: #399369;
                width: 40px;
            }
        }
    }
}

.bottom-cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 2rem;
    @media screen and (max-width:1024px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width:640px) {
        grid-template-columns: repeat(1, 1fr);
    }
    .card-items{
        background: #399369;
        color: @white;
        padding: 20px;
        text-align: center;
        position: relative;
        border-radius: 15px;
        &:hover{
            background: rgba(#399369, 0.9);
        }
        .arrow-link{
            position: absolute;
            top: 10px;
            right: 20px;
            .icon{
                width: 20px;
            }
        }
        .icon{
            width: 50px;
            height: 50px;
            fill: @white;
        }
        h4{
            font-size: 1.5rem;
            font-weight: bold;
            color: @white;
        }
    }
}