@import "./_variables.less";

html,
body {
	min-height: 100vh;
	font-family: @font-family;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	font-smoothing: antialiased !important;
	font-feature-settings: normal !important;
	font-variant: normal !important;
	font-size: @font-size-base + 2;
	scroll-behavior: smooth;
	padding: 0;
	margin: 0;
	background: @white;
	color: @secondary-color;
	scroll-behavior: smooth;
}

body {
	& > {
		iframe{
			display: none; 
		}
	}
}

main {
	position: relative;
}

a {
	text-decoration: none;
}

.icon {
	width: 16px;
	height: auto;
	fill: #000;
	transition: fill 0.3s;

	&.icon-white {
		fill: #fff;
	}
}

#root {
	overflow-x: hidden;
}

.anchor-tag {
	cursor: pointer;
}

h1 {
	font-size: 2.5rem;
	font-weight: bold;
}

h2 {
	font-weight: bold;
	font-size: 2rem;
}

h3 {
	font-size: @font-size-xlg + 2;
	font-weight: 600;
}

h4 {
	font-size: 1.4rem;
}

p {
	font-size: @font-size-base + 2;
}


.main-content {
	max-width: 1440px;
	width: 100%;
	margin: auto;
	.ant-layout-sider {
		background: @sidebar-bg;
		width: 100%;
		max-width: 290px;
	}

	.right-content-wrapper {
		min-height: calc(100vh - 95px);
		background: none;
		padding: 15px 45px 0 45px;
		max-height: calc(100vh - 65px);
		overflow-y: auto;
		@media screen and (max-width:767px) {
			min-width: 60vw;
			padding: 20px 15px 0;
		}
		img{
			max-width: 100%;
		}
	}
}

.ant-layout {
	background: none;

	.ant-layout-footer {
		&.main-footer {
			color: @secondary-color;
			border-top: 1px solid rgb(235, 235, 235);
			text-align: left;
			padding: 15px 0;
			height: 30px;
			background: @white;
			margin-top: 3rem;
			@media screen and (max-width:767px) {
				white-space: nowrap;
			}
		}
	}
}

.disable-class {
	pointer-events: none;
}

.ScrollbarsCustom-TrackY {
	background: rgb(220, 220, 220) !important;
	width: 6px !important;

	.ScrollbarsCustom-ThumbY {
		background: #52B788 !important;
	}
}

.ScrollbarsCustom-TrackX {
	background: rgb(220, 220, 220) !important;
	height: 6px !important;

	.ScrollbarsCustom-ThumbX {
		background: #52B788 !important;
	}
}

a.link-tag{
	color: @success-color;
}